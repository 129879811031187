<template>
  <section class="main">
    <div class="socials">
      <a target="_blank" href="https://www.instagram.com/nopalvlissingen">
        <i class="fa-brands fa-instagram"></i>
      </a>

      <a target="_blank" href="https://www.facebook.com/nopalvlissingen">
        <i class="fa-brands fa-square-facebook"></i>
      </a>
    </div>
    <img src="./assets/logo.svg" />

    <div class="banner">
      <p>We are closed for a winter break from February 8th until March 8th.</p>
    </div>

    <div class="intro">
      <p>
        At Nopal we serve food made from fresh and mostly local ingredients.
        Inspired by the wonders of Mexico. Come and join us for a beer, cocktail
        and a taco or 2,3,4...
      </p>

      <!-- <button class="btn btn-primary">View menu</button> -->
    </div>

    <div class="opening">
      <!-- <h2>Opening hours</h2>

      <div class="columns col-gapless">
        <div class="col-12 col-sm-12">
          <h3>Wednesday to Saturday</h3>
          <p>17:00 - till late</p>
        </div>

        <div class="col-12 col-sm-12">
          <p class="text-center">
            <br />Tuesday open on request for all your Events, Parties &amp;
            Private Dinners! <br />
            Send us a DM or e-mail for more information.
          </p>
        </div>

      </div>

      <p class="highlight">- Kitchen open till late -</p> -->

      <p class="highlight-alt">
        Walk in or send us a DM or
        <a href="mailto:info@nopalvlissingen.nl">e-mail</a> <br />
        to book a spot!
      </p>
    </div>

    <footer>
      <i class="fa-solid fa-location-dot"></i> Oude Markt 18, Vlissingen,
      Netherlands
    </footer>
  </section>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style scoped lang="scss">
body,
#app {
  background: #f4f0e6 !important;
}
.banner {
  padding: 24px;
  background-color: #000;
  color: #fff;
  text-align: center;
  width: 100%;
  font-size: 21px;
  margin-top: 24px;
  p {
    margin: 0;
  }
}
.main {
  background: #f4f0e6;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  @media (max-width: 640px) {
    height: 80vh;
    margin-top: 20vh;
  }

  font-family: brandon-grotesque, sans-serif;

  .intro {
    width: 80vw;
    font-size: 21px;
    max-width: 600px;
    text-align: center;
    padding-top: 40px;

    @media (max-width: 640px) {
      font-size: 17px;
    }

    button.btn-primary {
      background: #000;
      border: 0;
      color: #fff;
      font-size: 21px;
      height: 48px;
      line-height: 48px;
      padding: 0;
      width: 200px;
    }
  }

  .opening {
    text-align: center;
    width: 100%;
    max-width: 500px;

    .col-6 {
      text-align: center;
      @media (max-width: 640px) {
        padding-top: 12px;
      }
    }

    .col-6:last-of-type {
      border-left: 1px solid #000;
      @media (max-width: 640px) {
        border: 0;
      }
    }

    h2 {
      font-size: 30px;
      margin-top: 24px;
      margin-bottom: 32px;
      @media (max-width: 640px) {
        margin-bottom: 24px;
        margin-top: 12px;
      }
      text-decoration: underline;
      text-transform: uppercase;
    }

    h3 {
      font-size: 20px;
      text-align: center;
      margin-bottom: 12px;
    }

    p {
      margin: 0;
    }

    p.highlight {
      margin-top: 24px;
      font-size: 21px;
      font-style: italic;
    }

    p.highlight-alt {
      font-size: 21px;
      font-weight: bold;
      max-width: 80%;
      text-align: center;
      margin: 0 auto;
      margin-top: 24px;
      a {
        color: inherit;
        text-decoration: underline;
      }
    }
  }
}
.soon {
  position: absolute;
  left: 0;
  top: 0;
  background: #000;
  color: #fff;
  height: 200px;
  width: 500px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 200px;
  padding-bottom: 20px;
  font-size: 24px;
  font-family: brandon-grotesque, sans-serif;
  transform: rotate(-40deg) translateX(-80px) translateY(-180px);
  @media (max-width: 640px) {
    padding-right: 150px;
    padding-bottom: 20px;
    transform: rotate(-40deg) translateX(-140px) translateY(-190px);
  }
}
img {
  width: 30vh;
  margin-top: 5vh;
  @media (max-width: 640px) {
    margin-top: 15vh;
  }
}
footer {
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* color: #fff; */
  font-size: 21px;
  padding-bottom: 24px;
  margin-top: 32px;
  font-family: brandon-grotesque, sans-serif;
  @media (max-width: 640px) {
    font-size: 18px;
    top: 50px;
  }
}
i {
  margin-right: 24px;
  color: #000;
}
.socials {
  position: absolute;
  right: 20px;
  top: 3vh;
  font-size: 48px;
  display: flex;
  flex-direction: column;

  @media (max-width: 640px) {
    right: 0;
    top: 24px;
    font-size: 40px;
    margin-top: -20vh;

    transform: scale(0.9);
  }
}
</style>
